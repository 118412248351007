import React from "react"
import styled from "styled-components"
import "normalize.css"

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
`

const Content = styled.div`
  margin-top: 6rem;
  font-size: 16px;
  line-height: 2.5rem;

  @media only screen and (min-width: 640px) {
    margin-top: 9rem;
    width: 50%;
  }
`

const Heading = styled.h3`
  font-weight: 400;
  margin: 0 0 0.4em;
  letter-spacing: -0.02em;

  color: grey;
  margin-bottom: 1.5rem;
  font-size: 16px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 2rem;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  padding: 0;
  list-style: none;

  :last-of-type {
    color: #b1b1b1;
  }
`

class Lists extends React.Component {
  render() {
    return (
      <Section>
      <Content>
        <Heading>Worked with</Heading>
        <List>
          <ListItem>Bambuser</ListItem>
          <ListItem>Electrolux</ListItem>
          <ListItem>Filmstaden</ListItem>
          <ListItem>Kronans Apotek</ListItem>
          <ListItem>Mentimeter</ListItem>
          <ListItem>Parks and Resorts</ListItem>
          <ListItem>Plick</ListItem>
          <ListItem>Robin Delselius Bageri</ListItem>
          <ListItem>Stockholm Pride</ListItem>
          <ListItem>& More…</ListItem>
        </List>
      </Content>
      <Content>
        <Heading>Contact</Heading>
        <List>
          <ListItem><a href="mailto:hello@oscarleman.se?subject=Hi%20there!" className="email-link">Email</a></ListItem>
          <ListItem><a href="https://linkedin.com/in/oscarleman" className="linkedin-link">Linkedin</a></ListItem>
        </List>
      </Content>
    </Section>
    )
  }
}

export default () => (
  <Lists />
)
